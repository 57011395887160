const initialState = {
  items: [],
}

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      let items = state.items.filter(i=>i.loader !== action.loader)
      const _item = state.items.find(i=>i.loader === action.loader)

      const item = { loader: action.loader, active: action.active, }
      if (typeof action.error === "boolean"){
        item.error = action.error
      } else if (_item){
        item.error = _item.error || false
      }

      return { ...state, items: [...items, item] }
    default:
      return state
  }
}

export default layoutReducer
