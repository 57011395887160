// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'

import loading from './loading'

import booking from '@src/views/bookings/store/reducer'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  
  
  
  

  loading,

  booking,
})

export default rootReducer
